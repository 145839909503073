import "./Paytable.scss";

import React from "react";
import { Row, Col } from "react-bootstrap";

const Paytable = () => {
	return (
		<div className="paytable">
			<div className="paytable--content">
				<div className="paytable--content-title">Paytable</div>
				<div className="paytable--content-container">
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle mt5">
								Numbers - <span>35/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Single Number
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Odd/Even - <span>1/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								Odd
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block">
								Even
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Low/High - <span>1/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								1-18
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block">
								19-36
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Dozens - <span>2/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								1-12
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block">
								13-24
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block">
								25-36
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Columns - <span>2/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								<div className="paytable--content-container-block-special">
									1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								<div className="paytable--content-container-block-special">
									2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block">
								<div className="paytable--content-container-block-special">
									3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-subtitle">
								Colors - <span>1/1</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="paytable--content-container-block red">
								Red
							</div>
						</Col>
						<Col>
							<div className="paytable--content-container-block black">
								Black
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default Paytable;
