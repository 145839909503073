import "./Header.scss";

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import hourglass from "../static/images/hourglass.png";

import { ReactComponent as Logo } from "../logo.svg";

const Header = (props) => {
	const {
		currentDraw,
		timerMinutes,
		timerSeconds,
		fullScreenToggler,
	} = props;

	const [spinHourglass, setSpinHourglass] = useState(false);

	useEffect(() => {
		setInterval(function() {
			setSpinHourglass((spinHourglass) => !spinHourglass);
		}, 2000);
	}, []);

	return (
		<header className="header">
			<Row>
				<Col xl lg="2" sm="3">
					<div
						className="header--logo"
						onClick={() => fullScreenToggler()}
					>
						<Logo className="MegaWinner" />
					</div>
				</Col>
				<Col xl lg="6" sm="9">
					<div className="header--current-draw">
						Current Draw: <span>{currentDraw}</span>
					</div>
					<div className="header--mobile-timer">
						Next Draw:{" "}
						<span>
							{timerMinutes}:{timerSeconds}
						</span>
					</div>
				</Col>
				<Col xl lg="4" className="header--timer">
					<div className="header--timer-container">
						<div
							className={`header--timer-container-icon ${
								spinHourglass ? "animation" : ""
							}`}
						>
							<img
								src={hourglass}
								className="MegaWinner"
								alt="MegaWinner"
							/>
						</div>

						<div className="header--timer-container-copy">
							Next Draw:
						</div>
						<div className="header--timer-container-time">
							{timerMinutes}:{timerSeconds}
						</div>
					</div>
				</Col>
			</Row>
		</header>
	);
};

export default Header;
