import "./History.scss";

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";

const History = props => {
	const { history, hotNumbers, coldNumbers, getColor } = props;

	const [reverseHistory, setReverseHistory] = useState(history);

	useEffect(() => {
		setReverseHistory(history.reverse());
	}, [history]);

	return (
		<div className="history">
			<div className="history--hot-numbers">
				<div className="history--hot-numbers-title">Hot Numbers</div>
				<div className="history--hot-numbers-container">
					<AnimatePresence>
						<Row noGutters>
							{hotNumbers.map((number, index) => (
								<Col key={index}>
									<motion.div
										initial={{ height: 0, opacity: 0 }}
										animate={{
											transition: {
												ease: "easeOut",
												duration: 0.35
											},
											height: "auto",
											opacity: 1
										}}
										exit={{ height: 0, opacity: 0 }}
										className={`history--hot-numbers-container-number ${getColor(
											number
										)}`}
									>
										{number}
									</motion.div>
								</Col>
							))}
						</Row>
					</AnimatePresence>
				</div>
			</div>

			<div className="history--cold-numbers">
				<div className="history--cold-numbers-title">Cold Numbers</div>
				<div className="history--cold-numbers-container">
					<AnimatePresence>
						<Row noGutters>
							{coldNumbers.map((number, index) => (
								<Col key={index}>
									<motion.div
										initial={{ height: 0, opacity: 0 }}
										animate={{
											transition: {
												ease: "easeOut",
												duration: 0.35
											},
											height: "auto",
											opacity: 1
										}}
										exit={{ height: 0, opacity: 0 }}
										className={`history--hot-numbers-container-number ${getColor(
											number
										)}`}
									>
										{number}
									</motion.div>
								</Col>
							))}
						</Row>
					</AnimatePresence>
				</div>
			</div>

			<div className="history--container">
				<div className="history--container-title">History</div>
				<div className="history--container-content">
					<AnimatePresence>
						{reverseHistory.map((number, index) => (
							<motion.div
								initial={{ height: 0, opacity: 0 }}
								animate={{
									transition: {
										ease: "easeOut",
										duration: 0.35
									},
									height: "auto",
									opacity: 1
								}}
								exit={{ height: 0, opacity: 0 }}
								key={index}
							>
								<Row noGutters>
									<Col>
										<div className="history--container-content-draw">
											{number.drawId}
										</div>
									</Col>
									<Col>
										<div
											className={`history--container-content-value ${getColor(
												number.result
											)}`}
										>
											{number.result}
										</div>
									</Col>
								</Row>
							</motion.div>
						))}
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
};

export default History;
