import React from 'react';

const useDeviceWidth = () => {
	const [deviceWidth, setDeviceWidth] = React.useState(null);

	React.useEffect(() => {
		const handleResize = () => {
			setDeviceWidth(typeof window !== 'undefined' ? window.innerWidth : null);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	React.useEffect(() => {
		setDeviceWidth(typeof window !== 'undefined' ? window.innerWidth : null);
	}, []);

	return [deviceWidth];
};

export default useDeviceWidth;
